<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        aria-hidden="true"
        class="fixed inset-0 transition-opacity"
        @click="closeModal"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;

      <div
        aria-labelledby="modal-headline"
        aria-modal="true"
        class="inline-block relative align-bottom justify-center px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6 rounded-md"
        role="dialog"
        style="background-color: #fff"
      >
        <div class="p-6">
          <h2 class="text-2xl font-bold mb-4">VCGmarkets Upgraded!</h2>
          <p class="mb-4">
            Welcome to VCGmarkets! We're excited to announce that we have
            upgraded our portal to enhance your experience.
          </p>

          <h3 class="text-xl font-bold mb-2">For Existing Customers</h3>
          <p class="mb-4">
            <b>Mandatory Password Reset:</b> As part of our security upgrade,
            please
            <a href="/forgot" class="text-blue-500 underline"
              >reset your password</a
            >. Click on 'Forgot Password?' at the login page and follow the
            email instructions to reset.
          </p>

          <h3 class="text-xl font-bold mb-2">For New Customers</h3>
          <p class="mb-4">
            No additional steps required! Please
            <a
              @click="closeModal"
              class="text-blue-500 underline cursor-pointer"
              >proceed to log in</a
            >
            as usual.
          </p>

          <p>
            This upgrade is part of our commitment to providing you with a
            secure and efficient service. We thank you for your cooperation and
            understanding. Enjoy the new and improved VCGmarkets portal!
          </p>

          <div class="flex gap-4 mt-4">
            <a href="/forgot" class="text-blue-500 underline"
              >Reset your password</a
            >
            <a
              @click="closeModal"
              class="text-blue-500 underline cursor-pointer"
              >Proceed to log in</a
            >
          </div>
        </div>

        <div
          class="h-7 flex items-center absolute top-2 right-2"
          @click="closeModal"
        >
          <button
            aria-label="Close panel"
            class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none"
          >
            <!-- Heroicon name: x -->
            <svg
              class="h-5 w-5"
              fill="none"
              stroke="#000"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 18L18 6M6 6l12 12"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "VcgMigrationResetPassword",

  computed: {
    ...mapGetters(["get_profile"]),
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
