<template>
  <div>
    <Navigation
      :show-language-switcher="true"
      :show-login="false"
      :show-user-menu="false"
    ></Navigation>
    <div
      v-if="
        logoName !== 'ICMTrader' &&
        logoName !== 'Promena' &&
        logoName !== 'Blackstone' &&
        logoName !== 'TDSouthAfrica' &&
        logoName !== 'TD365' &&
        logoName !== 'OneTradingMarkets' &&
        logoName !== 'EagleGlobalMarkets' &&
        logoName !== 'TradeCoreUK' &&
        logoName !== 'OrbitInvest' &&
        logoName !== 'ICMMENA' &&
        logoName !== 'GCCBrokers' &&
        logoName !== 'VCGMarkets' &&
        logoName !== 'TradiNext' &&
        logoName !== 'PolarisMarkets' &&
        logoName !== 'Dzengi' &&
        !logoName.includes('FXView') &&
        logoName !== 'AAAFX' &&
        logoName !== 'Zulu'
      "
      class="justify-center flex mt-10"
    >
      <img
        :src="require(`../assets/${logoName}-flag.png`)"
        alt="logo"
        class="md:block h-auto mr-2"
      />
      <h4
        class="my-auto text-center text-l leading-9 font-medium text-gray-900"
      >
        ICM Capital {{ logoName.replace("ICM", "").replace("Capital", "UK") }}
      </h4>
    </div>
    <div v-if="logoName === 'ICMMENA'" class="justify-center flex mt-10">
      <h4
        class="my-auto text-center text-l leading-9 font-medium text-gray-900"
      >
        ICMMENA
      </h4>
    </div>
    <div
      class="login-vue min-h-screen-90 w-full bg-gray-100 flex flex-col justify-center pb-12 sm:px-6 lg:px-8 m-auto relative"
    >
      <div v-if="$store.getters.get_suitable.result === false" class="mb-5">
        <div class="rounded-md bg-yellow-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: exclamation -->
              <svg
                class="h-5 w-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  fill-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm leading-5 font-medium text-yellow-800">
                {{ $t("attention-needed") }}
              </h3>
              <div
                v-if="
                  logoName === 'ICMMENA' ||
                  logoName === 'GCCBrokers' ||
                  logoName === 'TradiNext'
                "
                class="mt-2 text-sm leading-5 text-yellow-700"
              >
                <p v-if="$store.getters.get_suitable.result">
                  {{ $store.getters.get_suitable.message }}
                </p>
                <p v-else>
                  {{
                    $t(
                      "Based-on-the-responses-you-have-provided-in-your-application"
                    )
                  }}
                </p>
              </div>
              <div v-else class="mt-2 text-sm leading-5 text-yellow-700">
                <p v-if="$store.getters.get_suitable.result">
                  {{ $store.getters.get_suitable.message }}
                </p>
                <p v-else-if="logoName === 'EagleGlobalMarkets'">
                  {{
                    $t(
                      "we-sincerely-apologise-but-your-answers-indicate-that-you-currently-do-not-have-sufficient-experienc"
                    )
                  }}
                  {{
                    $t(
                      "inexperienced-traders-might-suffer-significant-financial-harm-which-7727716458132e79dc48791444061076"
                    )
                  }}
                </p>
                <p v-else>
                  {{
                    $t(
                      "we-sincerely-apologise-but-your-answers-indicate-that-you-currently-do-not-have-sufficient-experienc"
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <div class="flex items-center sm:items-stretch justify-center mb-8">
          <a v-if="logoName === 'Dzengi'" href="/">
            <img
              :src="require(`../assets/Dzengi/logo-icon.png`)"
              alt="Logo"
              class="md:block h-32 w-auto"
            />
          </a>
          <a v-if="logoName.includes('FXView')" href="/">
            <img
              :src="require(`../assets/FXView-logo.svg`)"
              alt="Logo"
              class="md:block h-10 md:h-12 w-auto"
            />
          </a>
          <a v-if="logoName === 'Zulu'" href="/">
            <img
              :src="require(`../assets/Zulu-logo.svg`)"
              alt="Logo"
              class="md:block h-10 w-auto"
            />
          </a>
          <a v-if="logoName.includes('AAAFX')" href="/">
            <img
              :src="require(`../assets/AAAFX-logo.png`)"
              alt="Logo"
              class="md:block h-14 w-auto"
            />
          </a>

          <a v-if="logoName.includes('Polaris')" href="/">
            <img
              :src="require(`../assets/PolarisMarkets-logo.png`)"
              alt="logo"
              class="md:block h-32 w-auto"
            />
          </a>
          <a
            v-if="logoName.includes('OrbitInvest')"
            href="http://www.orbit-invest-trade.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/OrbitInvest-logo.png`)"
              alt="logo"
              class="md:block h-24 w-auto"
            />
          </a>
          <a
            v-if="logoName.includes('ICMTrader')"
            href="https://www.icmtrader.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/ICMTrader-logo.svg`)"
              alt="logo"
              class="md:block h-14 w-auto"
            />
          </a>
          <a
            v-if="logoName === 'ICMAP' || logoName === 'ICMCapital'"
            href="https://www.icmcapital.com"
            target="_blank"
          >
            <img
              :src="require(`../assets/icm-logo.svg`)"
              alt="logo"
              class="md:block h-auto w-auto"
            />
          </a>
          <a
            v-if="logoName === 'ICMVC' || logoName === 'ICMMU'"
            href="https://www.icm.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/icm-logo.svg`)"
              alt="logo"
              class="md:block h-auto w-auto"
            />
          </a>
          <a v-if="logoName === 'TradeCoreUK'" href="#" target="_blank">
            <img
              :src="require(`../assets/dxtrade-logo.svg`)"
              alt="logo"
              class="md:block h-auto w-32"
            />
          </a>
          <a
            v-if="logoName === 'TradiNext'"
            href="https://tradinext.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/TradiNext-logo.png`)"
              alt="logo"
              class="md:block h-auto w-32"
            />
          </a>
          <a
            v-if="logoName === 'ICMMENA'"
            href="http://www.icmmena.com"
            target="_blank"
          >
            <img
              :src="require(`../assets/icmmena-logo.svg`)"
              alt="logo"
              class="md:block h-14 w-auto"
            />
          </a>
          <a
            v-if="logoName === 'GCCBrokers'"
            href="https://www.gccbrokers.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/GCCBrokers-logo.png`)"
              alt="logo"
              class="md:block h-auto w-12"
            />
          </a>
          <!-- <a href="https://icmcapital.co.uk/" target="_blank" v-if="logoName === 'ICMCapital'">
            <img class="md:block h-auto w-auto" :src="require(`../assets/icm-logo.svg`)" alt="logo">
          </a> -->
          <a v-if="logoName === 'Promena'">
            <img
              :src="require(`../assets/${logoName}-logo.png`)"
              alt="logo"
              class="md:block h-auto w-56"
            />
          </a>
          <a
            v-if="logoName === 'ICMEU'"
            href="https://www.icmcapital.co.uk/"
            target="_blank"
          >
            <img
              :src="require(`../assets/${logoName}-logo.png`)"
              alt="logo"
              class="md:block h-auto w-auto"
            />
          </a>
          <a v-if="logoName === 'OneTradingMarkets'">
            <img
              :src="require(`../assets/${logoName}-logo.png`)"
              alt="logo"
              class="md:block h-20 w-auto"
            />
          </a>
          <a v-if="logoName === 'VCGMarkets'">
            <img
              :src="require(`../assets/${logoName}_new_black_logo.svg`)"
              alt="logo"
              class="md:block h-20 w-auto mt-4"
            />
          </a>
          <a v-if="logoName === 'EagleGlobalMarkets'">
            <img
              :src="require(`../assets/${logoName}-logo.svg`)"
              alt="logo"
              class="md:block h-20 w-auto"
            />
          </a>
        </div>
        <h2
          v-if="
            logoName === 'ICMTrader' &&
            (this.$store.getters.get_lang === 'en' ||
              this.$store.getters.get_lang === '')
          "
          class="mt-6 text-center text-2xl leading-9 font-bold text-gray-900"
        >
          Sign in to your account
        </h2>
        <h2
          v-else-if="!logoName.includes('ICM')"
          class="mt-6 text-center text-2xl leading-9 font-bold text-gray-900"
        >
          {{ $t("log-in-to-your-account") }}
        </h2>
        <h2
          v-else
          class="mt-6 text-center text-2xl leading-9 font-bold text-gray-900"
        >
          {{ $t("log-in-to-your-icm-access") }}
        </h2>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <!-- <div class="maintenance-notice">
          <p>
            Our Client portal is undergoing maintenance now.
            We request you to not register/login using this portal. We apologize for any inconvenience
            and thank you for your understanding.
          </p>
        </div> -->
        <form @submit.prevent="handleSubmit(login)">
          <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow rounded-sm sm:px-10 m-4">
              <div class="h-20">
                <label
                  class="block text-sm font-medium leading-5 text-gray-700"
                  for="email"
                >
                  {{ $t("Email") }}
                </label>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      id="email"
                      v-model="username"
                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      name="email"
                      type="email"
                    />
                    <!--                     :class="errors.email ? 'border-red-300 placeholder-red-300 focus:shadow-outline-red focus:border-red-300' : ''"/>-->
                  </div>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
                <p class="text-sm text-red-600 h-4"></p>
              </div>
              <div class="mt-2 h-26">
                <label
                  class="block text-sm font-medium leading-5 text-gray-700"
                  for="password"
                >
                  {{ $t("Password") }}
                </label>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      id="password"
                      v-model="password"
                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      name="password"
                      type="password"
                    />
                  </div>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="flex items-center justify-between mt-2">
                <div v-if="$route.name === 'Login'" class="text-sm leading-5">
                  <router-link
                    class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    to="/forgot"
                  >
                    {{ $t("forgot-your-password") }}
                  </router-link>
                </div>
                <div v-else class="text-sm leading-5">
                  <router-link
                    class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    to="/ib/forgot"
                  >
                    {{ $t("forgot-your-password") }}
                  </router-link>
                </div>
              </div>

              <div class="mt-4">
                <span class="block w-full rounded-md shadow-sm">
                  <button
                    :disabled="isDisabled"
                    class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    type="submit"
                  >
                    {{ $t("log-in") }}
                  </button>
                </span>
                <p
                  v-if="$route.name === 'Login'"
                  class="mt-2 text-center text-sm leading-5 text-gray-600 max-w"
                >
                <!-- BR#18 - Mbeng Atemson - hide dont-have-an-account for icmvc 22/08/2024 and icmcapital 02/10/2024 -->
                  <router-link v-if="!hide()"
                    class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    to="/accounts"
                  >
                    {{ $t("dont-have-an-account") }}
                  </router-link>
                </p>
                <p
                  v-else
                  class="mt-2 text-center text-sm leading-5 text-gray-600 max-w"
                >
                  <router-link
                    class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    to="/ib/signup"
                  >
                    {{ $t("dont-have-an-account") }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <VcgMigrationResetPassword
      v-if="showVcgMigrationResetPassword"
      @close="showVcgMigrationResetPassword = false"
    />
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";
import { mapGetters } from "vuex";
import { checkValidDate } from "@/lib/checkValidDate";
import { SET_ICM_POPUP } from "@/store/mutations";
import VcgMigrationResetPassword from "@/views/VCGMigrationResetPassword.vue";

export default {
  name: "Login",
  components: {
    VcgMigrationResetPassword,
    Navigation,
  },
  data() {
    return {
      logoName: process.env.VUE_APP_WHITELABEL,
      username: "",
      password: "",
      isDisabled: false,
      showVcgMigrationResetPassword: false,
    };
  },
  computed: {
    ...mapGetters(["get_profile"]),
  },
  created() {
    console.log("WHITELABEL", this.logoName);
  },
  methods: {
    login() {
      let data = {
        username: this.username,
        password: this.password,
      };
      if (
        this.logoName.includes("ICM") ||
        this.logoName === "ICMTrader" ||
        this.logoName === "TDSouthAfrica" ||
        this.logoName === "Promena" ||
        this.logoName === "TradeCoreUK" ||
        this.logoName === "FXViewGlobal" ||
        this.logoName === "FXViewEU" ||
        this.logoName === "AAAFXGlobal" ||
        this.logoName === "AAAFXEU"
      ) {
        data.whitelabel = this.logoName;
      }
      if (this.$route.path.includes("ib") && this.logoName === "TradeCoreUK") {
        delete data.whitelabel;
      }
      this.isDisabled = true;
      this.$store
        .dispatch("login", data)
        .then((res) => {
          this.isDisabled = false;

          // ICMVC and ICMMU show academy modal on each login
          // if the user country is not from these 4 mentioned below
          if (this.logoName === "ICMVC" || this.logoName === "ICMMU") {
            this.$store.dispatch("profile").then((resp) => {
              if (
                this.get_profile.address.addr_country !== "Malaysia" &&
                this.get_profile.address.addr_country !== "Vietnam" &&
                this.get_profile.address.addr_country !== "Indonesia" &&
                this.get_profile.address.addr_country !== "Thailand"
              ) {
                const isValidDate = checkValidDate("2024-01-25T16:00:00+01:00");
                if (isValidDate) {
                  this.$store.commit("SET_ICM_POPUP", true);
                }
                //this.$store.commit('SET_ICM_ACADEMY_MODAL', true);
                this.$store.commit("SET_ICM_ZENDESK_VERSION", "version1");
              } else {
                this.$store.commit("SET_ICM_ZENDESK_VERSION", "version2");
              }

              // if(this.get_profile.address.addr_country === 'United Arab Emirates'){
              //   this.$store.commit('SET_ICM_ACADEMY_MODAL', true);
              // }
            });
          }

          if (res.data.info.profile.type === "IB") {
            this.$router.replace("/ib/");
          } else {
            this.$router.replace("/");
          }
        })
        .catch((err) => {
          const t = this.$t.bind(this);
          this.isDisabled = false;
          this.$notify({
            group: "foo",
            text: `${t("your-email-or-password-was-incorrect")}`,
            type: "warn",
          });
        });
    },
    hide() {
      //BR#18 - Mbeng Atemson - hide dont-have-an-account for icmvc 22/08/2024 and icmcapital 02/10/2024
      return false || this.logoName === 'ICMVC' || this.logoName.includes("ICM Capital VC") || this.logoName.includes("VC") || this.logoName.includes("ICM Capital UK") || this.logoName.includes("UK") || this.logoName.includes("Capital");
      //return false || this.logoName.includes("ICM") || this.logoName.includes("ICM Capital VC");
      
    },
  },
};
</script>
<style scoped>
.maintenance-notice {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 20px;
}
</style>
